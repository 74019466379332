body {
  margin: 0;
  background-color: black;
  font-family: "Nunito Sans", sans-serif;
}

.default {
  display: block;
  margin-top: 8em;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}
